import { functions, auth } from "../firebase/instance";
import { httpsCallable } from "firebase/functions";
import { NewsStoriesRes } from "../redux/interfaces/news-stories.interface";
import { addFirebaseDataToStories } from "../redux/thunks/news-stories/stories.thunks-action-creators";
//import { fetchMockData } from "./mockApi/fetchMock";

export interface FetchFeedParam {
  medias: string[];
  interests: string[];
  pageNum: number;
}

interface Res {
  data: NewsStoriesRes;
}

const defaultRes = {status: "", totalResults: 0, articles: []};

export const fetchFeed = 
  async ({ medias, interests, pageNum }: FetchFeedParam): Promise<NewsStoriesRes> => {
    const currentUser = auth.currentUser;
    const userToken = await currentUser?.getIdToken(true);

    if (userToken) {
      try {            

        if (!Array.isArray(medias) || !Array.isArray(interests) || typeof pageNum !== 'number') {
          throw new Error("Invalid input parameters: medias and interests must be arrays, and pageNum must be a number.");
        }
        
        // call clound function fetchNews, with userToken (for cloud function to talk to db)
        const fetchNews = httpsCallable(functions, "fetchNewsV2");

        const response = await fetchNews({
          medias,
          interests,
          userToken
        }) as unknown as Res;
        console.log({ stories: response.data })
        // articles (response) come back from cloud function (server): stories from NewsApi with userShares
        // const newsfeed = await addFirebaseDataToStories(response.data?.articles, 'STORIES ACTIONS');
        // return newsfeed;
        return response.data;
      } catch (err) {
        console.error(err);
        return defaultRes;
      }
    } else {
      console.error("User is not logged in");
      return defaultRes;
    }
  };

  export const fetchPerigonFeed = 
  async ({ medias, interests, pageNum }: FetchFeedParam): Promise<NewsStoriesRes> => {
    const currentUser = auth.currentUser;
    const userToken = await currentUser?.getIdToken(true);

    if (userToken) {
      try {            
        // call clound function fetchNews, with userToken (for cloud function to talk to db)
        const fetchNews = httpsCallable(functions, "fetchArticlesPerigon");

        const response = await fetchNews({
          medias,
          interests,
          pageNum,
          userToken
        }) as unknown as Res;
        // articles (response) come back from cloud function (server): stories from NewsApi with userShares
        // const newsfeed = await addFirebaseDataToStories(response.data?.articles, 'STORIES ACTIONS');
        // return newsfeed;
        // console.log({ res: response.data }, "<--- RES");
        return response.data;
      } catch (err) {
        console.error(err);
        return defaultRes;
      }
    } else {
      console.error("User is not logged in");
      return defaultRes;
    }
  };

export const getFeedAndDbData = async ({ account, num = null, stories }: any) => {
  let getFeed = true ? fetchPerigonFeed : fetchFeed;
  //getFeed = fetchFeed;

  const pagination = num === null ? stories?.pageNum : num;

  const res = await getFeed(
    { medias: account.medias, interests: account.interests, pageNum: pagination }
  );
  
  const data = await addFirebaseDataToStories(res.articles);
  return {data, pagination};
}
