import { useState } from "react";
import useOnce from "../../../hooks/useOnce";
import "./MediaSelector.css";
import { firestore } from "../../../firebase/instance";
import { collection, getDocs} from "firebase/firestore";

interface Param {
  onChange: Function;
  medias: string[];
  height?: string
}

const MediaSelector = ({ onChange, medias, height }: Param) => {
  // const [signUp, setSignUp] = useState<SignUpState>({
  //   ...signUpState
  // });

  const [apiSources, setApiSources] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const onSelect = (id: string) => {
    const mediaIdx = medias.indexOf(id);
    if (mediaIdx !== -1) {
      const newMedias = [...medias];
      newMedias.splice(mediaIdx, 1);
      onChange(newMedias);
    } else {
      onChange([ ...medias, id ]);
    }
  };

  useOnce(() => {
    async function fetchData() {
      // getDocs(query(perigonSources))
      //   .then((querySnapshot: QuerySnapshot) => {

      //     const sources: any[] = [];

      //     querySnapshot.forEach((doc) => {
      //       const { name, logoFavIcon, logoSquare, domain } = doc.data();
      //       const source = {
      //         id: doc.id,
      //         name: name,
      //         icon: logoSquare?.url || logoFavIcon?.url,
      //         perigonApiId: domain
      //       };

      //       sources.push(source);
      //     });
      //     setApiSources(sources);
      //   })

      try {
        setLoading(true);
        const sourcesRef = collection(firestore, "perigon-sources");
        console.log(sourcesRef);
        const querySnapshot = await getDocs(sourcesRef);
        const sources: any[] = [];

        if (querySnapshot.empty) {
          console.log('No media sources found in Firebase');
          setError('No media sources available');
          return;
        }

        querySnapshot.forEach((doc) => {
          const { name, iconPath, newsApiId, print_name } = doc.data();
          const source = {
            id: doc.id,
            name: print_name || name,
            icon: `/img/medias/${iconPath}`,
            perigonApiId: newsApiId
          };
          sources.push(source);
        });

        console.log('Fetched sources:', sources);
        setApiSources(sources);
      } 
      catch (err) 
      {
        console.error('Error fetching media sources:', err);
        setError('Failed to load media sources');
      }
      finally 
      {
        setLoading(false);
      }
    }

    fetchData();
  });

  if (loading) {
    return <div className="media-loading">Loading media sources...</div>;
  }

  if (error) {
    return <div className="media-error">{error}</div>;
  }

  const containerHeight = height ?? "";

  return (
    <ul className={`media-icon-container ${containerHeight}`}>
      {apiSources.map((source) => {
        const isSelected = medias.indexOf(source.perigonApiId) > -1;

        const className = isSelected ? "active" : "";

        return (
          <div key={source.id} className="media-container">
            <div className={`img-container ${className}`}>
              <img
                src={`${source.icon}`}
                alt={source.name}
                onClick={() => {
                  onSelect(source.perigonApiId);
                }}
                className="media-icon"
              />
            </div>
            <div className="stories-source">{source.name}</div>
          </div>
        );
      })}
    </ul>
  );
};

export default MediaSelector;
